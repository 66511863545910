:root {
    --primaryColor: #0e3d46;
    --secondaryColor: #cb5e27;
}

.MortImpactBarDivider{
    transition: 0.3s ease;
    height: 400px;
    width: 5px;
    background-color: var(--secondaryColor);
    position: absolute;
    bottom: 5%;
}

.MortImpactChart{
    height: 450px;
}

@media only screen and (min-width:0px) and (max-width: 600px){
    .MortImpactBarDivider{
        display: none;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px){
    .MortImpactBarDivider{
        left: 51.5%
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
    .MortImpactBarDivider{
        left: 42.5%
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
    .MortImpactBarDivider{
        left: 34.5%;
    }
}

@media only screen and (min-width: 1537px){
    .MortImpactBarDivider{
        left: 34.5%;
    }
}