:root {
    --primaryColor: #0e3d46;
    --secondaryColor: #cb5e27;
}

.PostROIPaper{
    min-height: 550px;
    width: 100%;
    padding: 16px;
    background-color: rgba(255,255,255, .8) !important;
    background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('../media/LastSlideBG.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.PostROIContainer{
    min-height: 550px;
}

@media only screen and (min-width:0px) and (max-width: 600px){
    .PostROIPaper{
        background-size: cover;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px){
    .PostROIPaper{
        background-size: cover;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
    
}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
    
}

@media only screen and (min-width: 1537px){
    
}