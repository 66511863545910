:root {
    --primaryColor: #0e3d46;
    --secondaryColor: #cb5e27;
}

.HCCImpactChart{
    height: 450px;
}

@media only screen and (min-width:0px) and (max-width: 600px){
    .MortBarDivider{
        display: none;
    }
}

@media only screen and (min-width: 601px) and (max-width: 900px){
    .MortBarDivider{
        left: 42.5%
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
    .MortBarDivider{
        left: 42.5%
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
    .MortBarDivider{
        left: 33.5%;
    }
}

@media only screen and (min-width: 1537px){
    .MortBarDivider{
        left: 33.5%;
    }
}