:root {
  --primaryColor: #0e3d46;
  --secondaryColor: #cb5e27;
}

#MktSiteContainer{
  height: 100%;
  overflow-x: hidden;
}

#MktSiteContainer::-webkit-scrollbar{
  background: transparent;
}
#MktSiteContainer::-webkit-scrollbar-button{
  background: transparent;
}
#MktSiteContainer::-webkit-scrollbar-corner{
  background: transparent;
}
#MktSiteContainer::-webkit-scrollbar-track {
  background: transparent;
}
#MktSiteContainer::-webkit-scrollbar-thumb{
  background: transparent;
}
#MktSiteContainer::-webkit-scrollbar-track-piece{
  background: transparent;
}

.slideContainer{
  position: relative;
  background: white;
  padding: 50px 0px;
}

.slideContainer > div {
  margin-left: 0px;
}

.titleContainer{
  min-height: 75px;
  padding: 16px;
  background-color: var(--primaryColor)
}

@media only screen and (min-width:0px) and (max-width: 600px){
}

@media only screen and (min-width: 601px) and (max-width: 900px){
}

@media only screen and (min-width: 901px) and (max-width: 1200px){

}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
}

@media only screen and (min-width: 1537px){
}