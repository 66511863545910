:root {
  --primaryColor: #0e3d46;
  --secondaryColor: #cb5e27;
}

.pieChart{
  height: 250px;
  width: 100%;
  background-color: transparent;
}

.highlightsTopBanner{
  width: 100%;
  height: 300px;
  position: absolute; z-index: 0; top: -2px;
  clip-path: polygon(0 0, 100% 0, 100% 25%, 0% 97%);
  background-color: var(--secondaryColor);
}

@media only screen and (min-width:0px) and (max-width: 600px){
}

@media only screen and (min-width: 601px) and (max-width: 900px){
}

@media only screen and (min-width: 901px) and (max-width: 1200px){

}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
}

@media only screen and (min-width: 1537px){
}