:root {
  --primaryColor: #0e3d46;
  --secondaryColor: #cb5e27;
}

.starChartContainer{ 
  height: 500px;
}

.starChart{
  height: 100%;
  width: 100%;
  background-color: transparent;
  min-height: 500px;
}

.starBottomBanner{
  width: 100%;
  height: 300px;
  position: absolute; z-index: 0; bottom: -2px;
  clip-path: polygon(0 75%, 100% 0, 100% 100%, 0% 100%);
  background-color: var(--secondaryColor)
}

@media only screen and (min-width:0px) and (max-width: 600px){
}

@media only screen and (min-width: 601px) and (max-width: 900px){
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
}

@media only screen and (min-width: 1537px){
}