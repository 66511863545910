:root {
  --primaryColor: #0e3d46;
  --secondaryColor: #cb5e27;
}

.CJPaper{
  transition: 0.3s ease;
  width: 100%;
  min-height: 600px;
  padding: 16px;
  border-radius: 16px;
}

.CJBackground{
  background: url(../media/background-line-graph-notext.webp) center / cover no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
}

.CJSectionText{
  flex: 1;
  display: flex;
  align-items: center; justify-content: center;
  width: 100%;
  margin: 10px 0px 10px 0px !important;
}

@media only screen and (min-width:0px) and (max-width: 600px){
  .CJContainer{
    min-height: 100%
  }

  .CJPaper{
    min-height: 100%;
    border-radius: 0px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px){
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
}

@media only screen and (min-width: 1537px){
}