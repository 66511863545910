:root {
  --primaryColor: #0e3d46;
  --secondaryColor: #cb5e27;
}

.MortImpactImg{
  width:100%;
  height: 500px;
}

@media only screen and (min-width:0px) and (max-width: 600px){
  .MortImpactImg{
    height: 350px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px){
}

@media only screen and (min-width: 901px) and (max-width: 1200px){
}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
}

@media only screen and (min-width: 1537px){
}