:root {
  --primaryColor: #0e3d46;
  --secondaryColor: #cb5e27;
}

.loginPaper{
  transition: 0.3s ease;
  width: 100%;
  min-height: 600px;
  padding: 16px;
  border-radius: 16px;
}

.loginLogoTitleContainer{
  min-height: 75px;
}

.loginTitle {
  text-align: center;
  min-height: 75px;
  margin-bottom: 15px;
}

.loginLogoImg{
  width: 100%;
  height: 75px;
  object-fit: contain;
  margin-bottom: 15px;
}

.loginIntroAndEmailContainer{
  min-height: 300px;
}

.loginOpenBtn, .loginReqAccessBtn{
  transition: 0.3s ease;
  background-color: var(--secondaryColor) !important;
  max-width: 90%;
  padding: 8px 0px;
  color: white;
  font-weight: bold !important;
}

.loginOpenBtn{
  width: 200px;
}
.loginReqAccessBtn{
  width: 200px;
}

.loginReqAccessBtn span, .loginOpenBtn span{
}

@media only screen and (min-width:0px) and (max-width: 600px){
  
  .loginContainer{
    min-height: 100%
  }

  .loginPaper{
    min-height: 100%;
    border-radius: 0px !important;
  }

  .loginOpenBtn, .loginReqAccessBtn{
    margin: 4px 0px !important;
    width: 100%;
    max-width: 100%;
  }

  .loginTitle{
    margin-bottom: 15px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px){
  .loginTitle{
    margin-bottom: 15px !important;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px){

}

@media only screen and (min-width: 1201px) and (max-width: 1536px){
}

@media only screen and (min-width: 1537px){
}